import React from 'react';
import { Link } from '../link/Link';
import { Icon } from '../icon/Icon';
import './main-header.scss';
import { Logo } from '../logo/Logo';
import { Button } from '../button/Button';
import { LinkPropsType } from '../../models/LinkPropsType';
import { links, logoProps } from '../../data/data';
import { Menu } from '../menu/Menu';

export const MainHeader: React.FC = () => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);      
      };

    const handleClose = () => {
        setAnchorEl(null);
      };

    return <div className='main-header'>
                <div className='main-header__container'>
                    <div className='main-header__wrapper'>
                        <Logo placeholder={'Virtual Reality'} logoProps={logoProps}/>
                        {links && <nav className='main-header__navigation'>
                            {links?.map((link: LinkPropsType, index: number) => {
                                return <Link key={link.id} classes={{root: `link ${index === links.length - 1 ? 'link--last' : ''}`}} href={link.url}>{link.text}{link.isDropdown ? <Icon iconName='KeyboardArrowDown'/> : ''}</Link>
                            })}
                            <Button href='/donate' classes={{root: 'button button--secondary', sizeLarge: 'button--large'}} size='large' text='Donate' disableRipple></Button>
                            <Button classes={{root: 'button button--primary', sizeLarge: 'button--large'}} size='large' text='Work with us' disableRipple href="/work-with-us"></Button>
                        </nav>}
                        <div className={`sub-navigation__menu ${openMenu ? 'open' : ''}`} onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleClick(e)}>
                                <div className="sub-navigation__menu-wrapper">
                                    {openMenu ? <Icon iconName="Close" className="icon"/> : <Icon iconName="Menu" className="icon"/> }
                                    <span className="sub-navigation__menu-text">MENU</span>

                                </div>
                        </div>
                        <Menu 
                                id={'sub-navigation__menu-mobile'} 
                                open={openMenu} 
                                onClose={handleClose}
                                anchorEl={anchorEl}
                                menuListProps={{
                                'aria-labelledby': 'basic-button',
                                }}
                            >
                                {links?.map((link: LinkPropsType, index: number) => {
                                return <Link key={link.id} classes={{root: `link ${index === links.length - 1 ? 'link--last' : ''}`}} href={link.url}>{link.text}{link.isDropdown ? <Icon iconName='KeyboardArrowDown'/> : ''}</Link>
                                })}
                            <Button classes={{root: 'button button--primary', sizeLarge: 'button--large'}} size='large' text='Work with us' disableRipple href="/work-with-us"></Button>
                        </Menu>
                    </div>
                    <div>
                    </div>
                </div>
            </div>

}