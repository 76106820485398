import React, { useEffect } from 'react';

interface RedirectToExternalProps {
    url: string;
}

const RedirectToExternal: React.FC<RedirectToExternalProps> = ({ url }) => {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return null;
};

export default RedirectToExternal;